module.exports = {
  siteTitle: 'Luca Previtali',
  introText: "Hi there! I'm Luca",
  siteDescription: `Luca Previtali Website`,
  keyWords: [
    'luca previtali',
    'lucaprevitali',
    'curriculum',
    'react',
    'frontend developer',
    'javascript',
  ],
  authorName: 'Luca Previtali',
  twitterUsername: 'lucaprevi21',
  githubUsername: 'lucaprevitali',
  devtoUsername: 'the_previ',
  authorAvatar: '/images/avatar_resized.jpg',
  social: {
    devto: 'https://dev.to/the_previ',
    github: 'https://github.com/lucaprevitali',
    linkedin: 'https://www.linkedin.com/in/previtaliluca/',
    twitter: 'https://twitter.com/lucaprevi21',
    email: 'lucaprevi@gmail.com',
  },
  siteUrl: 'https://lucaprevitali.dev',
  pathPrefix: '/luca-previtali-dev',
  siteCover: '/images/pexels-sergei-starostin-6636474.jpg',
  googleAnalyticsId: 'UA-69742620-1',
  background_color: '#ffffff',
  background_light: '#fdfdfd',
  theme_color: '#25303B',
  fontColor: '#000000cc',
  enableDarkmode: false,
  enableHeader: false,
  display: 'minimal-ui',
  icon: 'src/assets/gatsby-icon.png',
  headerLinks: [
    {
      label: 'Home',
      url: '/',
    },
    {
      label: 'Portfolio',
      url: '/portfolio',
    },
  ],
}
